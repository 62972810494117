<template>
  <ValidationProvider
    :rules="validationRules"
    class="question column is-8"
    :ref="`${question.qid}-validation-provider`"
    :vid="`${question.qid}-vid`"
    :mode="'passive'"
    tag="div"
    v-slot="{ errors, dirty, valid }"
  >
    <div class="question-content card">
      <div class="card-content">
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }"
        >
          <b-input
            :disabled="loading"
            :value="value[question.qid]"
            @input="$emit('input', { [question.qid]: $event })"
            custom-class="fa-input"
            type="textarea"
            rows="1"
            :placeholder="$t('free_answer.placeholder')"
            v-autogrow
          ></b-input>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { TextareaAutogrowDirective } from "@/directives/textarea-auto-grow.directive";

export default {
  name: "FreeAnswer",
  mixins: [QuestionMixin],
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  mounted() {
    this.$emit("mounted", "is-8");
  },
};
</script>

<style lang="scss">
.textarea[rows] {
  min-height: 32px;
}

textarea.fa-input {
  background-color: transparent;
  box-shadow: none;
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border: none;
  border-bottom: 1px solid $light-green;
  border-radius: 0;
  padding: 0;
  outline: 0;

  &:focus {
    box-shadow: none !important;
  }
}
</style>
